import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { ExaminationProtocol } from '../models/ExaminationProtocol';

@Injectable({
  providedIn: 'root'
})
export class ExaminationProtocolsService {
  private protocols: ExaminationProtocol[] = [];
  private host: string = "https://pdp.papug.eu:/api/"
  private protocolsURL: string = "Protocol"

  constructor(private http: HttpClient) { 
    this.protocols = [
      new ExaminationProtocol(1, "Przykładowe 1", "Example protocol 1", [0,1]),
      new ExaminationProtocol(2, "Przykładowe 2", "Example protocol 2", [0,2,3]),
      new ExaminationProtocol(3, "Przykładowe 3", "Example protocol 3", [0,1,2,3]),
    ]
  }

  public getExaminationProtocols(): Promise<ExaminationProtocol[]> {
    let result: ExaminationProtocol[] = [];

    return new Promise((resolve, reject) => {

      this.http.get<ExaminationProtocol[]>(this.host+this.protocolsURL).subscribe(protocols => {
        for (let protocol of protocols) {
          try {
            result.push(new ExaminationProtocol (
              protocol.id,
              protocol.name_pl_PL,
              protocol.name_en_US,
              protocol.exerciseTypeIDs
            ));
          } catch (e) {
            continue;
          }
        }
        resolve(result);
      }, error => {
        reject(error);
    });
  });
  }
}
