<div class="card mb-3 rounded-5 shadow-sm">
    <div class="card-header text-center py-1 bg-primary text-white">
        {{langStrings['patient']}}
    </div>
    <div class="card-body"><app-patientcard></app-patientcard></div>
</div>

<div class="card mb-3 rounded-5 shadow-sm">
    <div class="card-header text-center py-1 bg-primary text-white">{{langStrings['testDetails']}}</div>
    <app-examinationdetails></app-examinationdetails>
</div>