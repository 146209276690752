import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Exercise } from '../models/Exercise';

@Injectable({
  providedIn: 'root'
})
export class ExercisesService {
  private exercises: Exercise[] = [];
  private host: string = "https://pdp.papug.eu:/api"
  private excercisesByVisitURL = "/Exercise/ByVisit/";
  private exerciseURL = "/Exercise/";

  constructor(private http: HttpClient) { }

  getExercisesByVisitID(visitID: number): Promise<Exercise[]> {
    let result: Exercise[] = [];

    return new Promise((resolve, reject) => {

      this.http.get<Exercise[]>(this.host + this.excercisesByVisitURL + visitID).subscribe(exercises => {
        for (let exercise of exercises) {
          try {
            result.push(new Exercise(
              exercise.id,
              exercise.typeId,
              exercise.sortWeight,
              exercise.name_pl_PL,
              exercise.name_en_US,
              exercise.desciption_pl_PL,
              exercise.desciption_en_US,
              exercise.duration,
              this.getDate(exercise.dateCompleted),
              exercise.results
            ));
          } catch (e) {
            console.log(e);
            continue;
          }
        }

        if (result.length > 0) {
          result.sort((a, b) => a.sortWeight - b.sortWeight);
        }

        resolve(result);

      }, error => {
        reject(error);
      });

    });
  }

  getDate(date: any) {
    if (date == null) { return null; }
    return new Date(date);
  }

  getExerciseByID(id: number): Observable<Exercise> {
    return of(this.exercises.find(excercise => excercise.id == id));
  }

  updateExercise(exercise: Exercise): Promise<number> {
    return new Promise((resolve, reject) => {

      this.http.patch<Exercise>(this.host + "/Exercise/" + exercise.id, exercise, {observe: 'response'}).subscribe(response => {
        resolve(response.status);
      }, error => {
        console.log("Rejecting updateExercise", error);
        reject(error);
      })

    })
  }
}
