import { Component, EventEmitter, OnInit } from '@angular/core';
import { Patient } from '../models/Patient';
import { Visit } from '../models/Visit';
import { LanguageService } from '../services/language.service';
import { PatientsService } from "../services/patients.service";
import { VisitsService } from '../services/visits.service';
import Translation from '../translation/patientlist.json'

// TODO
// Patient selection

@Component({
  selector: 'app-patientlist',
  templateUrl: './patientlist.component.html',
  styleUrls: ['./patientlist.component.css']
})
export class PatientlistComponent implements OnInit {
  patients: Patient[] = [];
  visits: Visit[] = [];
  public langStrings;
  public selectedPatient: Patient;
  public validSelection = false;
  public showSelector = true;
  private selectedVisit: Visit;
  private newVisitEvent: EventEmitter<number> = new EventEmitter<number>();
  public patientToEdit: Patient;

  constructor(
    private visitsService: VisitsService,
    private patientsService: PatientsService,
    private langService: LanguageService) {
    
   }

  ngOnInit(): void {
    this.patientsService.getPatients().then(patients => {
      this.patients = patients;
      if (this.patients.length > 0) {
        let firstID = this.patients[0].getID();
        this.selectPatient(firstID);
      }
    }, error => {
      // TODO Pretty error handling
      console.log(error);
      alert("Error: " + error.statusText);
    });
    
    this.langStrings = Translation[this.langService.getLang()];
    // console.log(this.selectedPatient);
  }

  updateVisits(): void {
    this.visitsService.getVisits(this.selectedPatient.getID()).then(visits => {
      this.visits = visits;
      // Ah yes, updating html
      this.showSelector = false;
      setTimeout(() => {
        this.showSelector = true;
      }, 1);
    });
  }

  updateList(newSelectedPatient: number): void {
    this.patientsService.getPatients().then(patients => {
      this.patients = patients;
      this.selectPatient(newSelectedPatient);
    }, error => {
      // TODO Pretty error handling
      console.log(error);
      alert("Error: " + error.statusText);
    });
  }

  selectPatient(id: number): void {
    this.selectedPatient = this.patients.find(patient => patient.getID() == id);
    this.validSelection = true;
    this.selectedVisit = undefined;
    this.updateVisits();
  }

  selectVisit(id: number): void {
    this.selectedVisit = this.visits.find(visit => visit.getID() === id);
  }

  addVisit(): void {
    this.visitsService.addVisit(this.selectedPatient.id, new Date()).then(visitID => {
      this.updateVisits();
      this.selectVisit(visitID);
      this.newVisitEvent.emit(visitID);
    });
  }

  editEvent(id: number): void {
    this.patientToEdit = this.patients.find(patient => patient.getID() === id);
    document.getElementById("addPatientButton").click();
  }

  addNewPatient(): void {
    this.patientToEdit = undefined;
    document.getElementById("addPatientButton").click();
  }

  deleteVisit(id: number) {
    this.visitsService.deleteVisit(this.selectedPatient.id, id).then(response => {
      this.updateVisits();
    }, error => {
      // TODO Pretty error handling
      console.log(error);
      alert("Error: " + error.statusText);
    });
  }

}
