import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  private connection: WebSocketSubject<any>;
  url: string = "wss://pdp.papug.eu/api/ws"
  private RETRY_SECONDS = 2; 

  constructor() { }

  // TODO: Error handling and reconnection

  connect(url?: string): Observable<any> {
    if (url != undefined) {
      this.connection = webSocket(url);
      return this.connection.pipe(retry());
    }

    this.connection = webSocket(this.url);
    return this.connection.asObservable();
  }

  send(message: any): void {
    this.connection.next(message);
  }

  close(): void {
    this.connection.complete();
  }

}
