<table class="table table-hover text-center">
    <thead>
        <tr>
            <th scope="col">{{langStrings['avg']}}</th>
            <th scope="col" colspan="3">{{langStrings['accelerometer']}}</th>
            <th scope="col" colspan="3">{{langStrings['gyroscope']}}</th>
        </tr>
        <tr>
            <th scope="col">{{langStrings['position']}}</th>
            <th scope="col">x</th>
            <th scope="col">y</th>
            <th scope="col">z</th>
            <th scope="col">x</th>
            <th scope="col">y</th>
            <th scope="col">z</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let result of exercise.results">
            <th scope="row">{{ deviceLocationTranslatedStrings[deviceLocations[result.position.toString()]] }}</th>
            <td>{{ result.accelerometer.x | roundOneDecimal }}</td>
            <td>{{ result.accelerometer.y | roundOneDecimal }}</td>
            <td>{{ result.accelerometer.z | roundOneDecimal }}</td>
            <td>{{ result.gyroscope.x | roundOneDecimal }}</td>
            <td>{{ result.gyroscope.y | roundOneDecimal }}</td>
            <td>{{ result.gyroscope.z | roundOneDecimal }}</td>
        </tr>
    </tbody>
</table>