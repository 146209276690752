import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PatientlistComponent } from './patientlist/patientlist.component';
import { DevicelistComponent} from './devicelist/devicelist.component'
import { ExaminationdetailsComponent } from './examinationdetails/examinationdetails.component';
import { VisitPrintViewComponent } from './visit-print-view/visit-print-view.component';
import { ProtocollistComponent } from './protocollist/protocollist.component';

const routes: Routes = [
  {path: '', component: PatientlistComponent},
  {path: 'patients', component: PatientlistComponent},
  {path: 'devices', component: DevicelistComponent},
  {path: 'print', component: VisitPrintViewComponent},
  {path: 'protocols', component: ProtocollistComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
