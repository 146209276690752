<div class="col col-md-7 mx-auto">
    <table class="table table-sm">
        <thead>
          <tr>
            <th class="text-center" scope="col">{{langStrings['device']}}</th>
            <th class="text-center" scope="col">{{langStrings['position']}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let device of devices">
            <th class="text-center fw-normal vertical-align-middle" scope="row">#{{device.name}}:</th>
            <td class="text-center">
                <div *ngIf="device.connectionStatus == 2" class="col col-7 mx-auto">
                    <select *ngIf="showOptions" required="" class="form-select form-select-sm" (change)="updatePosition(device, $event.target.value)">
                        <option *ngFor="let enum of deviceLocationsOnlyString" [selected]="enum == deviceLocations[device.position]" value="{{enum}}">{{deviceLocationTranslatedStrings[enum]}}</option>
                    </select>
                </div>
                <app-connection-status *ngIf="device.connectionStatus != 2" [connectionStatus]="device.connectionStatus"></app-connection-status>
            </td>
          </tr>
        </tbody>
      </table>
</div>


<!-- <div class="col col-md-7 p-3 mx-auto border rounded">
    <div *ngFor="let device of devices" class="d-flex mb-1">
        <div class="col col-6 d-flex align-items-center justify-content-center">
            Device #{{device.name}}:
        </div>
        <div *ngIf="device.connectionStatus == 2" class="col col-5">
            <select *ngIf="showOptions" required="" class="form-select form-select-sm" (change)="updatePosition($event.target.value)">
                <option *ngFor="let enum of deviceLocationsOnlyString" [selected]="enum == deviceLocations[device.position]" value="{{enum}}">{{deviceLocationTranslatedStrings[enum]}}</option>
            </select>
        </div>
            <app-connection-status *ngIf="device.connectionStatus != 2" [connectionStatus]="device.connectionStatus"></app-connection-status>
    </div>
</div> -->