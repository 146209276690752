import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { ConnectionStatusType } from '../models/ConnectionStatusType';
import { Device } from '../models/Device';
import { DeviceLocation } from '../models/DeviceLocation';

@Injectable({
  providedIn: 'root'
})
export class DevicesService {
  private devices: Device[] = [];
  host: string = "https://pdp.papug.eu:/api/"
  devicesURL: string = 'device';
  deviceURL: string = 'device/';
  deletaAllURL: string = 'device';
  createURL: string = 'device';
  scanURL: string = 'device/scan';


  constructor(private http: HttpClient) { 
  }

  createDevice(macAddress: string, name: string, photoUrl: string, lastSeen: Date, position: DeviceLocation, connectionStatus: ConnectionStatusType, batteryLevel: number): Promise<Device> {
    let newDevice = new Device(0, macAddress, name, photoUrl, lastSeen, position, connectionStatus, batteryLevel);
    return new Promise((resolve, reject) => {

      this.http.post<Device>(this.host + this.createURL, newDevice).subscribe(result => {
        resolve(result);
      }, error => {
        reject(error);
      })

    })
  }

  getDevices(): Promise<Device[]> {
    let result: Device[] = [];

    return new Promise((resolve, reject) => {

      this.http.get<Device[]>(this.host + this.devicesURL).subscribe(devices => {
        for (let device of devices) {
          try {
            result.push(new Device(
              device.id,
              device.macAddress,
              device.name,
              device.photoUrl,
              new Date(device.lastSeen),
              device.position,
              device.connectionStatus,
              device.batteryLevel
            ));

          } catch (e) {
            console.log(e);
            continue;
          }
        }

        if (result.length > 0) {
          result.sort((a, b) => { return a.id - b.id });
        }

        resolve(result);
      }, error => {
       reject(error); 
      });

    });
  }

  scanDevices(): Promise<number> {
    return new Promise((resolve, reject) => {
      this.http.get(this.host + this.scanURL, {observe: 'response'}).subscribe(response => {
        resolve(response.status);
      }, error => {
        reject(error);
      })
    });
  }

  deleteAllDevices(): Promise<number> {
    return new Promise((resolve, reject) => {
      this.http.delete(this.host + this.deletaAllURL, {observe: 'response'}).subscribe(response => {
        resolve(response.status);
      }, error => {
        reject(error);
      })
    });
  }

  updateDevice(device: Device): Promise<number> {
    return new Promise((resolve, reject) => {

      this.http.patch<Device>(this.host + this.deviceURL + device.id, device, {observe: 'response'}).subscribe(response => {
        resolve(response.status);
      }, error => {
        console.log("Rejecting updateDevice", error);
        reject(error); 
      })

    });
  }

  async createDebugDevices(): Promise<void> {
    await Promise.all([
    this.createDevice('00:00:00:00:00:00', '23', "assets/devices/23.png", new Date(), DeviceLocation.None, ConnectionStatusType.CONNECTED, 82),
    this.createDevice('00:00:00:00:00:01', '26', "assets/devices/23.png", new Date(), DeviceLocation.Head, ConnectionStatusType.DISCONNECTED, 0),
    this.createDevice('00:00:00:00:00:02', '48', "assets/devices/23.png", new Date(), DeviceLocation.NapeOfTheNeck, ConnectionStatusType.CONNECTING, 0),
    this.createDevice('00:00:00:00:00:03', '12', "assets/devices/23.png", new Date(), DeviceLocation.L5, ConnectionStatusType.CONNECTED, 100),
    this.createDevice('00:00:00:00:00:04', '34', "assets/devices/23.png", new Date(), DeviceLocation.LeftThigh, ConnectionStatusType.CONNECTED, 23),
    ]);
  }

}
