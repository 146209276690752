<div class="row">
    <div class="col-7">
        <app-connection-status class="fw-bold" [connectionStatus]="device.connectionStatus"></app-connection-status><br>

        <i class="bi bi-search"></i>
        <span class="mb-0"> {{langStrings['last_seen']}}:<br>{{device.lastSeen.toLocaleString(langStrings['lang'], {dateStyle: "short", timeStyle: "short"})}}</span><br>

        <i class="bi bi-signpost"></i>
        <span class="mb-0"> {{langStrings['macAddress']}}:<br>{{device.macAddress}}</span><br>

    </div>
    <div class="col-5">
        <div class="d-flex">
            <img class="mb-1 float-center mx-auto" src="{{device.photoUrl}}" height="60px">
        </div>
        <div>
            <h5 class="text-center"><strong>#{{device.name}}</strong></h5>
            <div class="d-flex justify-content-center">
                <span class="text-center badge fs-6 text-white" [ngClass]="{'bg-primary': device.connectionStatus == 2, 'bg-secondary': device.connectionStatus != 2}">{{deviceLocationStrings[deviceLocations[device.position]]}}</span>
            </div>
        </div>
    </div>
</div>

<div class="text-center mt-3">
    <i class="bi bi-battery-half"></i>
    <span class="mb-0"> {{langStrings['battery']}}: {{device.batteryLevel}}%</span><br>
</div>

<div class="progress" style="height: 5px;">
    <div class="progress-bar bg-primary" [ngClass]="{'bg-primary': device.batteryLevel > 30, 'bg-danger': device.batteryLevel <= 30}" role="progressbar" style="width: {{device.batteryLevel}}%;"></div>
  </div>