import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Patient } from '../models/Patient';
import { SexType } from '../models/SexType';
import { SocialIDType } from '../models/SocialIDType';
import { LanguageService } from '../services/language.service';
import { PatientsService } from '../services/patients.service';
import Translation from '../translation/patientcard.json'

// Editable
// Verify PESEL when editing (just warning, allow override, always display yellow warning)

@Component({
  selector: 'app-patientcard',
  templateUrl: './patientcard.component.html',
  styleUrls: ['./patientcard.component.css']
})
export class PatientcardComponent implements OnInit {
  @Input() public patient: Patient;
  public langStrings;
  public displayedSex;
  public IDTypeAsString: string;
  public age: string = "";
  public isPrintMode: boolean = false;
  public doctorNotes = "";
  @Output() editEvent = new EventEmitter<number>();
  
  constructor(
    public langService: LanguageService,
    private route: ActivatedRoute,
    private patientsService: PatientsService) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      // If we are in print mode, we need to get the visit from the visits service
      if(params.get('patientID') != null) {
        let id = parseInt(params.get('patientID'));
        this.isPrintMode = true;
        this.getPatientByID(id);
      }
    });
  }

  getPatientByID(id: number) {
    this.patientsService.getPatientByID(id).then(patient => {
      this.patient = patient;
      this.ngOnChanges();
    });
  }

  ngOnChanges() {
    this.langStrings = Translation[this.langService.getLang()];
    this.updateSex();
    this.updateIDType();
    this.updateAge();
    this.updateNotes();
  }

  updateSex() {
    let patientSexString = SexType[this.patient.getSex()];
    this.displayedSex = this.langStrings[patientSexString];
  }

  updateIDType() {
    this.IDTypeAsString = SocialIDType[this.patient.getSocialIDType()];
  }

  updateAge() {
    let today = new Date();
    let age = today.getFullYear() - this.patient.getBirthDate().getFullYear();
    let m = today.getMonth() - this.patient.getBirthDate().getMonth();
    if (m < 0 || (m === 0 && today.getDate() < this.patient.getBirthDate().getDate())) {
      age--;
    }
    this.age = age.toString();
  }

  emitEdit() {
    let id = this.patient.getID()
    this.editEvent.emit(id);
  }

  updateNotes() {
    this.doctorNotes = this.patient.getNotes();
  }

  saveNotes() {
    this.patient.doctorNotes = this.doctorNotes;
    this.patientsService.editPatient(this.patient).then(result => { }, error => {
      // TODO Pretty error handling
      console.log(error);
      alert("Error: " + error.statusText);
    });
  }

}
