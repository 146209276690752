import { Component, EventEmitter, Input, OnChanges, OnInit, Output, Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../services/language.service';
import { SocialIDType } from "../models/SocialIDType";
import Translation from '../translation/add-patient-modal.json'
import { SexType } from '../models/SexType';
import { ValidationType } from '../models/ValidationType';
import { PatientsService } from '../services/patients.service';
import { Patient } from '../models/Patient';
import { SocialID } from '../models/SocialID';

// TODO Autofill based on PESEL
// TODO Uncrap socialIDTypeValidation and sexValidation
@Component({
  selector: 'app-add-patient-modal',
  templateUrl: './add-patient-modal.component.html',
  styleUrls: ['./add-patient-modal.component.css']
})
export class AddPatientModalComponent implements OnInit, OnChanges {
  @Input() patient: Patient;
  @Output() private updateEventEmitter: EventEmitter<number> = new EventEmitter<number>();
  public langStrings;
  public socialIDTypes = this.getHalfEnum(SocialIDType);
  public sexTypes = this.getHalfEnum(SexType);
  public validationType = ValidationType;

  public isEditMode: boolean = false;

  public showSocialIDType: boolean = true;
  public showSexType: boolean = true;

  public firstName: string = "";
  public lastName: string = "";
  public socialIDType: string = "";
  public socialID: string = "";
  public birthday: string = "";
  public birthdayButDate: Date;
  public sex: string = "";
  public height: string = "";
  public weight: string = "";
  public leftThighLength: string = "";
  public rightThighLength: string = "";
  public leftShinLength: string = "";
  public rightShinLength: string = "";
  public leftAnkleHeight: string = "";
  public rightAnkleHeight: string = "";
  public ankleHeight: string = "";

  public selectedDate = new Date("1970");
  public age: string = "";

  public isFirstNameValid: ValidationType = ValidationType.empty;
  public isLastNameValid: ValidationType = ValidationType.empty;
  public isSocialIDTypeValid: ValidationType = ValidationType.empty;
  public isSocialIDValid: ValidationType = ValidationType.empty;
  public isBirthdayValid: ValidationType = ValidationType.valid;
  public isSexValid: ValidationType = ValidationType.empty;
  public isHeightValid: ValidationType = ValidationType.empty;
  public isWeightValid: ValidationType = ValidationType.empty;
  public isLeftThighLengthValid: ValidationType = ValidationType.empty;
  public isRightThighLengthValid: ValidationType = ValidationType.empty;
  public isLeftShinLengthValid: ValidationType = ValidationType.empty;
  public isRightShinLengthValid: ValidationType = ValidationType.empty;
  public isLeftAnkleHeightValid: ValidationType = ValidationType.empty;
  public isRightAnkleHeightValid: ValidationType = ValidationType.empty;
  public isAnkleHeightValid: ValidationType = ValidationType.empty;

  constructor(public langService: LanguageService, private patientsService: PatientsService) { }

  ngOnInit(): void {
    this.langStrings = Translation[this.langService.getLang()];
  }

  ngOnChanges(): void {
    if (this.patient != undefined) {
      this.isEditMode = true;

      this.firstName = this.patient.getFirstName();
      this.lastName = this.patient.getLastName();
      this.socialIDType = SocialIDType[this.patient.getSocialIDType()];
      this.socialID = this.patient.getSocialID();
      this.birthdayButDate = this.patient.getBirthDate();
      this.sex = SexType[this.patient.getSex()];
      this.height = this.patient.getHeight().toString();
      this.weight = this.patient.getWeight().toString();
      this.leftThighLength = this.patient.getLeftThighLength().toString();
      this.rightThighLength = this.patient.getRightThighLength().toString();
      this.leftShinLength = this.patient.getLeftShinLength().toString();
      this.rightShinLength = this.patient.getRightShinLength().toString();
      this.leftAnkleHeight = this.patient.getLeftAnkleHeight().toString();
      this.rightAnkleHeight = this.patient.getRightAnkleHeight().toString();

      this.selectedDate = this.patient.getBirthDate();
      this.ageUpate();

      this.finalVerification();
    } else {
      this.isEditMode = false;
      
      this.firstName = "";
      this.lastName = "";
      this.socialIDType = "";
      this.socialID = "";
      this.birthdayButDate = undefined;
      this.sex = "";
      this.height = "";
      this.weight = "";
      this.leftThighLength = "";
      this.rightThighLength = "";
      this.leftShinLength = "";
      this.rightShinLength = "";
      this.leftAnkleHeight = "";
      this.rightAnkleHeight = "";

      this.isFirstNameValid = ValidationType.empty;
      this.isLastNameValid = ValidationType.empty;
      this.isSocialIDTypeValid = ValidationType.empty;
      this.isSocialIDValid = ValidationType.empty;
      this.isBirthdayValid = ValidationType.valid;
      this.isSexValid = ValidationType.empty;
      this.isHeightValid = ValidationType.empty;
      this.isWeightValid = ValidationType.empty;
      this.isLeftThighLengthValid = ValidationType.empty;
      this.isRightThighLengthValid = ValidationType.empty;
      this.isLeftShinLengthValid = ValidationType.empty;
      this.isRightShinLengthValid = ValidationType.empty;
      this.isLeftAnkleHeightValid = ValidationType.empty;
      this.isRightAnkleHeightValid = ValidationType.empty;
      this.isAnkleHeightValid = ValidationType.empty;
    }

    // cuz we need to update html ;___;
    this.showSocialIDType = false;
    this.showSexType = false;
    setTimeout(() => {
      this.showSocialIDType = true;
      this.showSexType = true;
    }, 1);
  }

  getHalfEnum(myEnum: Object): string[] {
    let halfLen = Math.ceil(Object.keys(myEnum).length / 2);
    return Object.keys(myEnum).slice(halfLen);
  }

  firstNameValidation(): boolean {
    if (this.firstName.length > 0) {
      this.isFirstNameValid = ValidationType.valid;
      return true;
    } else {
      this.isFirstNameValid = ValidationType.invalid;
      return false;
    }
  }

  lastNameValidation(): boolean {
    if (this.lastName.length > 0) {
      this.isLastNameValid = ValidationType.valid;
      return true;
    } else {
      this.isLastNameValid = ValidationType.invalid;
      return false;
    }
  }

  socialIDTypeValidation(typeStr?: string): boolean {
    if (typeStr) {
      if (typeStr in SocialIDType) {
        this.isSocialIDTypeValid = ValidationType.valid;
        this.socialIDType = typeStr;
        return true;
      } else {
        this.isSocialIDTypeValid = ValidationType.invalid;
        return false;
      }
    } else {
      if (this.socialIDType in SocialIDType) {
        this.isSocialIDTypeValid = ValidationType.valid;
        return true;
      }
    }
    this.isSocialIDTypeValid = ValidationType.invalid;
    return false;
  }

  socialIDValidation(): boolean {
    if (this.socialID.length > 0) {
      this.isSocialIDValid = ValidationType.valid;
      return true;
    } else {
      this.isSocialIDValid = ValidationType.invalid;
      return false;
    }
  }
  
  sexValidation(typeStr ?: string): boolean {
    if (typeStr) {
      if (typeStr in SexType) {
        this.isSexValid = ValidationType.valid;
        this.sex = typeStr;
        return true;
      } else {
        this.isSexValid = ValidationType.invalid;
        return false;
      }
    } else {
      if (this.sex in SexType) {
        this.isSexValid = ValidationType.valid;
        return true;
      } 
    }
    this.isSexValid = ValidationType.invalid;
    return false;
  }

  heightValidation(): boolean {
    let num = parseFloat(this.height);
    if (num > 0) {
      this.isHeightValid = ValidationType.valid;
      return true;
    } else {
      this.isHeightValid = ValidationType.invalid;
      return false;
    }
  }

  weightValidation(): boolean {
    let num = parseFloat(this.weight);
    if (num > 0) {
      this.isWeightValid = ValidationType.valid;
      return true;
    } else {
      this.isWeightValid = ValidationType.invalid;
      return false;
    }
  }

  leftThighLengthValidation(): boolean {
    let num = parseFloat(this.leftThighLength);
    if (num > 0) {
      this.isLeftThighLengthValid = ValidationType.valid;
      return true;
    } else {
      this.isLeftThighLengthValid = ValidationType.invalid;
      return false;
    }
  }

  rightThighLengthValidation(): boolean {
    let num = parseFloat(this.rightThighLength);
    if (num > 0) {
      this.isRightThighLengthValid = ValidationType.valid;
      return true;
    } else {
      this.isRightThighLengthValid = ValidationType.invalid;
      return false;
    }
  }

  leftShinLengthValidation(): boolean {
    let num = parseFloat(this.leftShinLength);
    if (num > 0) {
      this.isLeftShinLengthValid = ValidationType.valid;
      return true;
    } else {
      this.isLeftShinLengthValid = ValidationType.invalid;
      return false;
    }
  }

  rightShinLengthValidation(): boolean {
    let num = parseFloat(this.rightShinLength);
    if (num > 0) {
      this.isRightShinLengthValid = ValidationType.valid;
      return true;
    } else {
      this.isRightShinLengthValid = ValidationType.invalid;
      return false;
    }
  }

  leftAnkleHeightValidation(): boolean {
    let num = parseFloat(this.leftAnkleHeight);
    if (num > 0) {
      this.isLeftAnkleHeightValid = ValidationType.valid;
      return true;
    } else {
      this.isLeftAnkleHeightValid = ValidationType.invalid;
      return false;
    }
  }

  rightAnkleHeightValidation(): boolean {
    let num = parseFloat(this.rightAnkleHeight);
    if (num > 0) {
      this.isRightAnkleHeightValid = ValidationType.valid;
      return true;
    } else {
      this.isRightAnkleHeightValid = ValidationType.invalid;
      return false;
    }
  }

  ageUpate(): void {
    document.getElementById("addPatientCalendarButton").click();
    let today = new Date();
    let age = today.getFullYear() - this.selectedDate.getFullYear();
    let m = today.getMonth() - this.selectedDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < this.selectedDate.getDate())) {
      age--;
    }
    this.age = age.toString();
    
    this.birthdayValidation();
  }

  birthdayValidation(): boolean {
    let timeMinus200Years = new Date();
    timeMinus200Years.setFullYear(timeMinus200Years.getFullYear() - 200);
    let timePlus200Years = new Date();
    timePlus200Years.setFullYear(timePlus200Years.getFullYear() + 200);
    if (this.selectedDate < timeMinus200Years || this.selectedDate > timePlus200Years) {
      this.isBirthdayValid = ValidationType.invalid;
      return false;
    } else {
      this.isBirthdayValid = ValidationType.valid;
      return true;
    }
  }

  finalVerification(): boolean {
    // Run all validations
    console.log("Running finalVerification()...")
    let success = true;
    if (!this.firstNameValidation()) {
      success = false;
    }
    if (!this.lastNameValidation()) {
      success = false;
    }
    if (!this.socialIDTypeValidation()) {
      success = false;
    }
    if (!this.socialIDValidation()) {
      success = false;
    }
    if (!this.sexValidation()) {
      success = false;
    }
    if (!this.heightValidation()) {
      success = false;
    }
    if (!this.weightValidation()) {
      success = false;
    }
    if (!this.leftThighLengthValidation()) {
      success = false;
    }
    if (!this.rightThighLengthValidation()) {
      success = false;
    }
    if (!this.leftShinLengthValidation()) {
      success = false;
    }
    if (!this.rightShinLengthValidation()) {
      success = false;
    }
    if (!this.leftAnkleHeightValidation()) {
      success = false;
    }
    if (!this.rightAnkleHeightValidation()) {
      success = false;
    }
    if (!this.birthdayValidation()) {
      success = false;
    }
    console.log("finalVerification(): " + success);
    return success;
  }

  addPatient(): void {
    let newPatientID: number;
    if (this.finalVerification()) {
        this.patientsService.createPatient(
        this.firstName,
        this.lastName,
        parseFloat(this.height),
        parseFloat(this.weight),
        parseFloat(this.leftThighLength),
        parseFloat(this.rightThighLength),
        parseFloat(this.leftShinLength),
        parseFloat(this.rightShinLength),
        parseFloat(this.leftAnkleHeight),
        parseFloat(this.rightAnkleHeight),
        this.selectedDate,
        SexType[this.sex],
        new SocialID(SocialIDType[this.socialIDType], this.socialID)
      ).then(result => { 
        newPatientID = result.id
        document.getElementById("dismissAddPatientModal").click();
        this.updateEventEmitter.emit(newPatientID);
      }, error => {
        // TODO Pretty error handling
        console.log("Error: " + error);
        alert("Error: " + error.statusText);
      });
    }
  }

  editPatient(): void {
    if (this.finalVerification()) {
      const newPatient = new Patient(
        this.patient.id,
        this.firstName,
        this.lastName,
        parseFloat(this.height),
        parseFloat(this.weight),
        parseFloat(this.leftThighLength),
        parseFloat(this.rightThighLength),
        parseFloat(this.leftShinLength),
        parseFloat(this.rightShinLength),
        parseFloat(this.leftAnkleHeight),
        parseFloat(this.rightAnkleHeight),
        this.selectedDate,
        SexType[this.sex],
        new SocialID(SocialIDType[this.socialIDType], this.socialID),
        this.patient.doctorNotes
      )
      this.patientsService.editPatient(newPatient).then(result => {
          this.patient = newPatient;
          this.updateEventEmitter.emit(this.patient.id);
          document.getElementById("dismissAddPatientModal").click();
        }, error => {
          // TODO Pretty error handling
          console.log(error)
          alert("Error: " + error.statusText);
      });
      }
    }
  }