import { Component, OnInit } from '@angular/core';
import { Device } from '../models/Device';
import { DeviceLocation } from '../models/DeviceLocation';
import { DevicesService } from '../services/devices.service';
import { LanguageService } from '../services/language.service';
import Translation from '../translation/device-position-selector.json'
import DeviceLocationTranslation from '../translation/DeviceLocation.json'

@Component({
  selector: 'app-device-position-selector',
  templateUrl: './device-position-selector.component.html',
  styleUrls: ['./device-position-selector.component.css']
})
export class DevicePositionSelectorComponent implements OnInit {
  public langStrings;
  public deviceLocationTranslatedStrings;
  public devices: Device[] = [];
  public showOptions = true;
  public deviceLocations = DeviceLocation;
  public deviceLocationsOnlyString = this.getHalfEnum(DeviceLocation);

  constructor(private langService: LanguageService, private devicesService: DevicesService) { }

  ngOnInit(): void {
    this.langStrings = Translation[this.langService.getLang()];
    this.deviceLocationTranslatedStrings = DeviceLocationTranslation[this.langService.getLang()];
    this.getDevices();

    const getDevicesLoop = setInterval(() => {
      this.getDevices();
    }, 15000);
  }

  getDevices() {
    this.devicesService.getDevices().then(devices => {
      this.devices = devices;

      this.showOptions= false;
      setTimeout(() => {
        this.showOptions = true;
      }, 1);
    });
  }

  getHalfEnum(myEnum: Object): string[] {
    let halfLen = Math.ceil(Object.keys(myEnum).length / 2);
    return Object.keys(myEnum).slice(halfLen);
  }

  updatePosition(device: Device, newPosition: string) {
    device.position = DeviceLocation[newPosition];
    this.devicesService.updateDevice(device);
  }

}
