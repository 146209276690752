<div class="p-3 rounded-3">

<div *ngIf="patient != undefined" class="row d-flex justify-content-center">
  <div class="col-3">
    <button *ngIf="!isPrintMode" class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#deleteModal"><i class="bi bi-trash"></i></button>
  </div>
    <div class="col-6 mb-3 d-flex justify-content-center">
        <form class="col-12 me-2">
            <!-- <input type="search" class="form-control" placeholder="Search..."> -->
            <select class="form-select is-invalid" [ngClass]="{'is-valid': validSelection, 'is-invalid': !validSelection}" required="" (change)="selectProtocol($event.target.value)">
                <!-- <option *ngFor="let patient of patients" value="{{patient.id}}">
                    {{patient.getFirstName()}} {{patient.getLastName()}} - {{patient.getBirthDate().toLocaleDateString(langService.getLang())}}
                </option> -->
                <option *ngIf="!validSelection" enabled="false" selected="true" disabled>{{langStrings['choose']}}</option>
                <option *ngFor="let protocol of examinationProtocols" [selected]="protocol.id === seletedProtocolID" value="{{protocol.id}}">{{protocol.getName(langStrings["lang"])}}</option>
            </select>
        </form>
    </div>
  <div *ngIf="isPrintMode" class="col-3"></div>
  <div *ngIf="!isPrintMode" class="col-3 text-end">
    <a href="/print?nav=false&patientID={{patient.id}}&visitID={{visit.id}}" target="_blank" class="btn btn-info btn-small"><i class="bi bi-printer-fill text-white"></i></a>
  </div>
</div>

<app-device-position-selector></app-device-position-selector>
<app-exercisedetails [excersises]="excersises" (needExerciseUpdateEmitter)="updateExaminations()" [isPrintMode]="isPrintMode"></app-exercisedetails>
  <!-- TODO: Allow for adding exercises to existing visit -->
  <!-- <div *ngIf="!isPrintMode" class="text-center mb-2"><button type="button" class="btn btn-secondary">{{langStrings['add-examination']}}</button></div> -->

  <div>
    <label for="validationServer01" class="form-label ms-1">{{langStrings['notes']}}:</label>
    <textarea *ngIf="!isPrintMode" id="description" class="form-control" [(ngModel)]="doctorNotes" (change)="saveNotes()" rows="5"></textarea>
    <div *ngIf="isPrintMode">{{doctorNotes}}</div>
  </div>
    
</div>

<!-- delete modal-->
<div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body fs-5">
        {{langStrings['confirm-delete']}}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{langStrings['cancel']}}</button>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="deleteVisit()">{{langStrings['delete']}}</button>
      </div>
    </div>
  </div>
</div>