<!-- Modal -->
<div class="modal fade" id="examinationProgressBar" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="examinationProgressBarLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title" id="examinationProgressBarLabel">{{examination.getName(langStrings['lang'])}}</h6>
          <button type="button" id="modalDismiss" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

          <div *ngIf="isRunningDifferentExercise" class="alert alert-danger d-flex align-items-center" role="alert">
            <i class="bi bi-exclamation-triangle-fill me-2 fs-5"></i>
            <div>
              {{langStrings['runningDifferentExerice']}}
            </div>
          </div>

          {{examination.getDescription(langStrings['lang'])}}

          <!-- Stopped -->
          <div *ngIf="exerciseState === 0" class="text-end d-flex justify-content-center">
            <div class="col-md-6">
              <div class="input-group my-3">
                <span class="input-group-text" id="inputGroup-sizing-default"><i class="bi bi-hourglass-split"></i></span>
                <input type="text" class="form-control" [ngClass]="{'is-valid': isDurationCorrect == true, 'is-invalid': isDurationCorrect == false}" (change)="exerciseTimeValidation()" aria-label="Sizing example input" [(ngModel)]="exerciseTime" aria-describedby="inputGroup-sizing-default">
                <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="addTime(-1)">-</button>
                <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="addTime(1)">+</button>
              </div>
            </div>
          </div>

          <!-- Countdown -->
          <div *ngIf="exerciseState === 1" class="mt-3">
            <div class="text-center fw-bold">
              {{langStrings['starting-in']}}: {{progress}}
            </div>
            <div class="progress">
              <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" [style.width]="progress*100/5+'%'" aria-valuenow="{{progress}}" aria-valuemin="0" aria-valuemax="5"></div>
            </div>
          </div>


          <!-- Progress -->
          <div *ngIf="exerciseState == 2" class="mt-3">
            <div class="text-center fw-bold">
              {{langStrings['progress']}}: {{progress}}%
            </div>
            <div class="progress">
              <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width]="progress+'%'" aria-valuenow="{{progress}}" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>

          <!-- Processing -->
          <div *ngIf="exerciseState == 4" class="mt-3">
            <div class="text-center fw-bold">
              {{langStrings['processing']}}
            </div>
            <div class="progress">
              <div class="progress-bar progress-bar-striped bg-success" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
            </div>
          </div>

          <!-- Finished -->
          <div *ngIf="exerciseState == 3" class="mt-3">
            <div class="text-center fw-bold">
              {{langStrings['finished']}}
            </div>
            <div class="progress">
              <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width]="progress+'%'" aria-valuenow="{{progress}}" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <div class="col form-check" style="justify-content: flex-start !important">
            <input class="form-check-input" type="checkbox" [(ngModel)]="autoContinue" id="flexCheckChecked">
            <label class="form-check-label" for="flexCheckChecked">
              {{langStrings["continue"]}}
            </label>
          </div>
          <div class="col justify-content-end d-flex">
            <button type="button" class="btn btn-secondary me-2" (click)="cancel()">{{langStrings['cancel']}}</button>
            <button type="button" id="start" class="btn btn-primary" (click)="start()">{{langStrings['start']}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>