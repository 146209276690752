import { Component, Input, OnInit, Pipe } from '@angular/core';
import { Exercise } from '../models/Exercise';
import { LanguageService } from '../services/language.service';
import Translation from "../translation/exercise-results.json";
import DeviceLocationTranslation from '../translation/DeviceLocation.json'
import { DeviceLocation } from '../models/DeviceLocation';

@Component({
  selector: 'app-exercise-results',
  templateUrl: './exercise-results.component.html',
  styleUrls: ['./exercise-results.component.css']
})
export class ExerciseResultsComponent implements OnInit {
  public langStrings;
  public deviceLocationTranslatedStrings;
  public deviceLocations = DeviceLocation;
  public deviceLocationsOnlyString = this.getHalfEnum(DeviceLocation);
  @Input() exercise: Exercise;

  constructor(private langService: LanguageService) { }

  ngOnInit(): void {
    this.langStrings = Translation[this.langService.getLang()];
    this.deviceLocationTranslatedStrings = DeviceLocationTranslation[this.langService.getLang()];
  }

  getHalfEnum(myEnum: Object): string[] {
    let halfLen = Math.ceil(Object.keys(myEnum).length / 2);
    return Object.keys(myEnum).slice(halfLen);
  }

}

@Pipe({name: 'roundOneDecimal'})
export class RoundOneDecimalPipe {
  transform (input: number) {
    return Math.round(input * 10) / 10;
  }
}