<header class="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom">
    <a href="/" class="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none">
      <img height="50rem" src="assets/logo.png">
    </a>

    <ul class="nav nav-pills col-12 col-md-auto mb-2 justify-content-center mb-md-0 m-1">
      <li class="nav-item mx-1"><a href="/patients" class="nav-link px-2" [ngClass]="{'active': isPatients, 'link-dark': !isPatients}">{{langStrings['button-patients']}}</a></li>
      <li class="nav-item mx-1"><a href="/devices" class="nav-link px-2" [ngClass]="{'active': isDevices, 'link-dark': !isDevices}">{{langStrings['button-devices']}}</a></li>
      <li class="nav-item mx-1"><a href="/protocols" class="nav-link px-2" [ngClass]="{'active': isProtocols, 'link-dark': !isProtocols}">{{langStrings['button-protocols']}}</a></li>
    </ul>

    <div class="col-md-3 text-end">
      <a class="pe-pointer" (click)="setLang(langStrings['next-lang'])">
        <img class="rounded-circle" height="50rem" src="{{langStrings['next-lang-flag']}}">
      </a>
    </div>
  </header>