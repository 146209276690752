export enum DeviceLocation {
    None = 0,
    Head = 1000000,
    NapeOfTheNeck = 2000000,
    L5 = 3000000,
    LeftThigh = 4000000,
    RightThigh = 5000000,
    LeftCalf = 6000000,
    RightCalf = 7000000,
    LeftAnkle = 8000000,
    RightAnkle = 9000000,
}