import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { Visit } from '../models/Visit';

@Injectable({
  providedIn: 'root'
})
export class VisitsService {
  private visits: Visit[] = [];
  host: string = "https://pdp.papug.eu:/api/"
  visitURL: string = "Visit/";
  visitsByPatientURL: string = "Visit/byPatient/";
  createByPatientURL: string = "Visit/byPatient/";
  deleteByPatientURL: string = "Visit/byPatient/";

  constructor(private http: HttpClient) { }

  getVisits(patientID: number): Promise<Visit[]> {
    // return of(this.visits.filter(visit => visit.patientID === patientID));


    let result: Visit[] = [];

    return new Promise((resolve, reject) => {


      this.http.get<Visit[]>(this.host+this.visitsByPatientURL+patientID).subscribe(visits => {
        for (let visit of visits) {
          try {
            result.push(new Visit(
              visit.id,
              new Date(visit.date),
              visit.protocolID,
              visit.exerciseIDs,
              visit.doctorNotes
            ));
          } catch (e) {
            console.log(e);
            continue;
          }
        }

        if (result.length > 0) {
          result.sort((a,b) => b.date.getTime() - a.date.getTime());
        }

        resolve(result);

      }, error => {
        reject(error);
      });




    });
  }

  getVisitByID(id: number): Promise<Visit> {
    return new Promise((resolve, reject) => {

      this.http.get<Visit>(this.host + this.visitURL + id).subscribe(visit => {
        resolve(new Visit(
          visit.id,
          new Date(visit.date),
          visit.protocolID,
          visit.exerciseIDs,
          visit.doctorNotes
        ));
      }, error => {
        reject(error);
      });
    });

  }

  updateVisit(newVisit: Visit): Promise<number> {
    return new Promise((resolve, reject) => {

      this.http.patch<Visit>(this.host + this.visitURL + newVisit.id, newVisit).subscribe(result => {
        resolve(result.id);
      }, error => {
        reject(error);
      });
    })
  }

  addVisit(patientID: number, date: Date, protocolID?: number, exerciseIDs?: number[]): Promise<number> {
    if (protocolID === undefined) {
      protocolID = 1;
    }
    let newVisit = new Visit(0, date, protocolID, exerciseIDs);

    return new Promise((resolve, reject) => {

      this.http.post<Visit>(this.host + this.createByPatientURL + patientID, newVisit).subscribe(result => {
        resolve(result.id);
      }, error => {
        reject(error);
      })

    })
  }

  deleteVisit(patientID:number, visitID: number): Promise<number> {
    return new Promise((resolve, reject) => {

      this.http.delete<Visit>(this.host + this.deleteByPatientURL + patientID + "/" + visitID, {observe: 'response'}).subscribe(response => {
        resolve(response.status);
      }, error => {
        reject(error);
      })

    })
  }
}
