import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { debounceTime, retry } from 'rxjs/operators';
import { Exercise } from '../models/Exercise';
import { ExerciseStatusType } from '../models/ExerciseStatusType';
import { ValidationType } from '../models/ValidationType';
import { Visit } from '../models/Visit';
import { LanguageService } from '../services/language.service';
import { WebsocketService } from '../services/websocket.service';
import Translation from "../translation/examination-progress-modal.json";

export enum ExerciseState {
  Stopped = 0,
  ShowCountdown = 1,
  ShowProgress = 2,
  Finished = 3,
  Processing = 4,
  Aborted = 5,
  Error = 100,
};
@Component({
  selector: 'app-examination-progress-modal',
  templateUrl: './examination-progress-modal.component.html',
  styleUrls: ['./examination-progress-modal.component.css']
})

export class ExaminationProgressModalComponent implements OnInit, OnChanges {
  @Input() visit: Visit;
  @Input() examination: Exercise;
  @Input() isDurationCorrect: boolean;
  @Output() onDurationChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() onNeedExerciseUpdate: EventEmitter<Exercise> = new EventEmitter<Exercise>();
  @Output() onSwitchToNextExercise: EventEmitter<boolean> = new EventEmitter<boolean>();
  public exerciseStates = ExerciseState;
  public exerciseState = ExerciseState.Stopped;
  public langStrings;
  public exerciseTime: string;
  public validationType = ValidationType;
  public progress: number = 0;
  public isRunningDifferentExercise = false;
  public autoContinue: boolean = false;
  private wsObservable: Observable<any>;
  private durationChangeNotifier: Subject<number> = new Subject<number>();
  private startChimeURL = "/assets/chimes/start/done-for-you-612.mp3"
  private finishChimeURL = "/assets/chimes/start/juntos-607.mp3"

  constructor(
    public langService: LanguageService,
    private wsService: WebsocketService) { }

  ngOnInit(): void {
    this.langStrings = Translation[this.langService.getLang()];
    this.wsObservable = this.wsService.connect();
    this.durationChangeNotifier.pipe(debounceTime(300)).subscribe((num) => {
      this.onDurationChange.emit(num);
      console.log("Duration changed to: " + num);
    });
    this.handleWebSocket();
  }

  ngOnChanges(): void {
    this.exerciseTime = this.examination.duration.toString();
  }

  addTime(diff: number) {
    let time = parseInt(this.exerciseTime);
    time += diff;
    this.exerciseTime = time.toString();
    this.exerciseTimeValidation();
  }

  handleWebSocket(): void {
    this.wsObservable.pipe(retry()).subscribe(
      (message: any) => {

        if (message.ExerciseID != this.examination.id) {
          this.isRunningDifferentExercise = true;
        } else {
          this.isRunningDifferentExercise = false;
        }

        if (message.Status == ExerciseStatusType.Countdown) {
          this.handleCountdown(message);
        }
        else if (message.Status == ExerciseStatusType.InProgress) {
          this.handleInProgress(message);
        }
        else if (message.Status == ExerciseStatusType.Finished) {
          this.handleFinish();
        } else if (message.Status == ExerciseStatusType.Processing) {
          this.handleProcessing();
        } else if (message.Status == ExerciseStatusType.Aborted) {
          this.handleAborted()
        }

      },
      (error) => {
        console.log(error);
      });
  }

  handleAborted() {
    this.exerciseState = ExerciseState.Stopped;
  }

  handleCountdown(message: any) {
    this.exerciseState = ExerciseState.ShowCountdown;
    this.progress = message.Progress;
    if (message.Progress == 0) {
      this.playStartChime();
    }
  }

  playStartChime() {
    let audio = new Audio();
    audio.src = this.startChimeURL;
    audio.load();
    audio.play();
  }

  playFinishChime() {
    let audio = new Audio();
    audio.src = this.finishChimeURL;
    audio.load();
    audio.play();
  }

  handleInProgress(message: any) {
    this.exerciseState = ExerciseState.ShowProgress;
    this.progress = message.Progress;
  }

  handleProcessing() {
    this.exerciseState = ExerciseState.Processing;
    this.progress = 100;
    this.playFinishChime();
  }

  handleFinish() {
    this.exerciseState = ExerciseState.Finished;
    this.isRunningDifferentExercise = false;
    setTimeout(() => {
      this.dismissModal();
      this.onNeedExerciseUpdate.emit(this.examination);
      if (this.autoContinue) {
        this.onSwitchToNextExercise.emit(true);
      }
    }, 1000);
  }

  exerciseTimeValidation() {
    let num = parseFloat(this.exerciseTime);
    if (num > 0) {
      this.durationChangeNotifier.next(num);
    } else {
    }
  }

  // TODO: Error handling: show error if no response after 10 seconds

  start() {
    console.log("Starting examination ID:" + this.examination.id);
    this.wsService.send(this.examination.id);
  }

  cancel() {
    this.wsService.send("abort");
    setTimeout(() => {
      this.dismissModal();
    }, 500);
  }

  dismissModal() {
    document.getElementById("modalDismiss").click();
    setTimeout(() => {
      this.exerciseState = ExerciseState.Stopped;
      }, 500);
  }

  ngOnDestroy() {

  }
}
