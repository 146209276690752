import { Component, OnInit } from '@angular/core';
import { Device } from '../models/Device';
import { DevicesService } from '../services/devices.service';
import { LanguageService } from '../services/language.service';
import Translation from "../translation/devicelist.json";

@Component({
  selector: 'app-devicelist',
  templateUrl: './devicelist.component.html',
  styleUrls: ['./devicelist.component.css']
})
export class DevicelistComponent implements OnInit {
  public langStrings;
  public devices: Device[] = [];
  public isScanning: boolean = false;

  constructor(public langService: LanguageService, private devicesService: DevicesService) { }

  ngOnInit(): void {
    this.langStrings = Translation[this.langService.getLang()];
    this.getDevices();

    const getDevicesLoop = setInterval(() => {
      this.getDevices();
    }, 5000);
  }

  getDevices() {
    this.devicesService.getDevices().then(device => {
      this.devices = device;
      });
  }

  startScan() {
    this.isScanning = true;
    this.devicesService.scanDevices().then(response => {
      this.isScanning = false;
      this.getDevices();
    }, error => {
      this.isScanning = false;
     // TODO: Pretty error handling
     console.log(error);
     alert("Error: " + error.statusText);
    });
  }

}
