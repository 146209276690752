import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Exercise } from '../models/Exercise';
import { Visit } from '../models/Visit';
import { ExercisesService } from '../services/exercises.service';
import { LanguageService } from '../services/language.service';
import Translation from "../translation/exercisedetails.json";

@Component({
  selector: 'app-exercisedetails',
  templateUrl: './exercisedetails.component.html',
  styleUrls: ['./exercisedetails.component.css']
})
export class ExercisedetailsComponent implements OnInit {
  @Input() isPrintMode: boolean;
  @Input() excersises: Exercise[] = [];
  @Output() private needExerciseUpdateEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  public selectedExercise: Exercise;
  private selectedExerciseIndex: number;
  public langStrings;
  isDurationCorrect: boolean = true;

  constructor(
    private langService: LanguageService,
    private exercisesService: ExercisesService) { }

  ngOnInit(): void {
    this.langStrings = Translation[this.langService.getLang()];
  }

  ngOnChanges() {
    //this.getExercises();
  }

  openModal(startAutomatically: boolean = false) {
    setTimeout(() => {
      document.getElementById('toggleProgressModal').click();
    }, 50);
  }

  startModal(exerciseID: number) {
    this.selectedExercise = this.excersises.find(exercise => exercise.id === exerciseID);
    this.selectedExerciseIndex = this.excersises.indexOf(this.selectedExercise);
    this.openModal()
  }

  switchToNextExercise() {
    console.log("Recived signal to start next exercise");
    console.log("Current index: " + this.selectedExerciseIndex);
    let newIndex = this.selectedExerciseIndex + 1;
    if (newIndex < this.excersises.length) {
      this.selectedExercise = this.excersises[newIndex];
      this.selectedExerciseIndex = newIndex;
      setTimeout(() => {
        this.openModal(true);
      }, 500);
    }
  }

  updaterExercise() {
    this.needExerciseUpdateEmitter.emit(true);
  }

  updateDurationOnSelectedExercise(duration: number) {
    console.log("updateDurationOnSelectedExercise");
    this.selectedExercise.duration = duration;
    this.isDurationCorrect = false;
    this.exercisesService.updateExercise(this.selectedExercise).then((status) => {
      if (status < 300 && status >= 200) {
        this.isDurationCorrect = true;
      }
      this.needExerciseUpdateEmitter.emit(true);
    });
  }

}
