import { SocialIDType } from "./SocialIDType";

export class SocialID {
    typeID: SocialIDType;
    type: string;
    value: string;

    constructor(typeID: SocialIDType, value: string) {
        this.typeID = typeID;
        this.type = SocialIDType[typeID];
        this.value = value;
    }

    getTypeID(): SocialIDType {
        return this.typeID;
    }

    getValue(): string {
        return this.value;
    }
}