import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Visit } from '../models/Visit';
import { LanguageService } from '../services/language.service';
import { VisitsService } from '../services/visits.service';
import Translation from '../translation/visitlist.json'

@Component({
  selector: 'app-visitlist',
  templateUrl: './visitlist.component.html',
  styleUrls: ['./visitlist.component.css']
})
export class VisitlistComponent implements OnInit {
  @Input() visits: Visit[] = [];
  @Input() private newVisitEvent: EventEmitter<number>;
  @Output() selectedVisitEmitter = new EventEmitter<number>();
  public selectedVisitID: number;
  public langStrings;

  constructor(private visitsService: VisitsService, private langService: LanguageService) { }

  ngOnInit(): void {
    this.langStrings = Translation[this.langService.getLang()];
    if (this.visits.length > 0) {
      this.setActive(this.visits[0].getID());
    }
    this.newVisitEvent.subscribe(id => {
      this.setActive(id);
    });
  }

  setActive(id: number): void {
    this.selectedVisitID = id;
    this.selectedVisitEmitter.emit(id);
  }

  ngOnChanges(): void {
    if (this.visits.length > 0) {
      this.setActive(this.visits[0].getID());
    }
  }

}
