import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { Patient } from "../models/Patient";
import { SexType } from "../models/SexType";
import { SocialIDType } from "../models/SocialIDType";
import { SocialID } from '../models/SocialID';
import { rejects } from 'assert';

// TODO
// Get patient by ID
// Modify patient
// Remove patient
// API

@Injectable({
  providedIn: 'root'
})
export class PatientsService {
  patients: Patient[] = [];
  host: string = "https://pdp.papug.eu:/api/"
  patientsURL: string = "Patient"
  patientURL: string = "Patient/"
  createURL: string = "Patient"
  deletaAllURL: string = "Patient"

  constructor(private http: HttpClient) { 
    
  }

  getPatients(): Promise<Patient[]> {
    let result: Patient[] = [];

    return new Promise((resolve, reject) => {


      this.http.get<Patient[]>(this.host+this.patientsURL).subscribe(patients => {
        for (let patient of patients) {
          try {
            result.push(new Patient (
              patient.id,
              patient.firstName,
              patient.lastName,
              patient.heightCM,
              patient.weightKG,
              patient.leftThighLength,
              patient.rightThighLength,
              patient.leftShinLength,
              patient.rightShinLength,
              patient.leftAnkleHeight,
              patient.rightAnkleHeight,
              new Date(patient.birthDate),
              patient.sex,
              new SocialID(patient.socialID.typeID, patient.socialID.value),
              patient.doctorNotes
            ));
          } catch (e) {
            continue;
          }
        }

        if (result.length > 0) {
          result.sort((a,b) => a.getLastName().localeCompare(b.getLastName()));
        }

        resolve(result);

      }, error => {
        reject(error);
      });




    });
  }

  deleteAllPatients(): Promise<number> {
    return new Promise((resolve, reject) => {
      this.http.delete(this.host + this.deletaAllURL, {observe: 'response'}).subscribe(response => {
        resolve(response.status);
      }, error => {
        reject(error);
      })
    });
  }

  createPatient(firstName: string, lastName: string, heightCM: number, weightKG: number, leftCalfLength: number, rightCalfLength: number, leftShinLength: number, rightShinLength: number, leftAnkleHeight: number, rightAnkleHeight: number, birthDate: Date, sex: SexType, socialID: SocialID): Promise<Patient> {
    let newPatient = new Patient(0, firstName, lastName, heightCM, weightKG, leftCalfLength, rightCalfLength, leftShinLength, rightShinLength, leftAnkleHeight, rightAnkleHeight, birthDate, sex, socialID, "")

    return new Promise((resolve, reject) => {

      this.http.post<Patient>(this.host + this.createURL, newPatient).subscribe(result => {
        resolve(result);
      }, error => {
        reject(error);
      })

    })
  }

  editPatient(patient: Patient): Promise<number> {
    return new Promise((resolve, reject) => {

      this.http.patch<Patient>(this.host + this.patientURL + patient.getID(), patient, {observe: 'response'}).subscribe(response => {
        console.log("Resolving editPatient", response.status);
        resolve(response.status);
      }, error => {
        console.log("Rejecting editPatient", error);
        reject(error); 
      })

    });
  }

  getPatientByID(id: number): Promise<Patient> {

    return new Promise((resolve, reject) => {

      this.http.get<Patient>(this.host + this.patientURL + id).subscribe(patient => {
        resolve(new Patient(
          patient.id,
          patient.firstName,
          patient.lastName,
          patient.heightCM,
          patient.weightKG,
          patient.leftThighLength,
          patient.rightThighLength,
          patient.leftShinLength,
          patient.rightShinLength,
          patient.leftAnkleHeight,
          patient.rightAnkleHeight,
          new Date(patient.birthDate),
          patient.sex,
          new SocialID(patient.socialID.typeID, patient.socialID.value),
          patient.doctorNotes))
        }, error => { reject(error) } );

    })
  }

  async createDebugPatients(): Promise<void> {
    await Promise.all([
      this.createPatient("Serafina", "Dąbrowski", 151, 66, 31, 31, 22, 22, 6,6, new Date(1991,4,6), SexType.Female, new SocialID(SocialIDType['Paszport Polsatu'], "91040676827")),
      this.createPatient("Aleksander", "Piotrowski", 169, 87, 33, 32, 24, 23, 7, 8, new Date(1958, 5, 19), SexType.Male, new SocialID(SocialIDType['PESEL'], "58051947571")),
      this.createPatient("Luba", "Allcock", 153.8, 199.4, 48.7, 49.5, 46.6, 45.8, 8.3, 9, new Date("2001-09-30T00:00:00Z"), SexType.Female, new SocialID(SocialIDType['Passport'], "14966912997"))
    ]);
  }
}
