import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../services/language.service';
import Translation from "../translation/patientlist.json";

@Component({
  selector: 'app-visit-print-view',
  templateUrl: './visit-print-view.component.html',
  styleUrls: ['./visit-print-view.component.css']
})
export class VisitPrintViewComponent implements OnInit {
  public langStrings;

  constructor(public langService: LanguageService) { }

  ngOnInit(): void {
    this.langStrings = Translation[this.langService.getLang()];
  }

}
