import { Component, Input, OnInit } from '@angular/core';
import { ConnectionStatusType } from '../models/ConnectionStatusType';
import { LanguageService } from '../services/language.service';
import Translation from '../translation/connection-status.json'

@Component({
  selector: 'app-connection-status',
  templateUrl: './connection-status.component.html',
  styleUrls: ['./connection-status.component.css']
})
export class ConnectionStatusComponent implements OnInit {
  @Input() connectionStatus: number
  public langStrings;
  public connectionStatusTypes = ConnectionStatusType;

  constructor(public langService: LanguageService) { }

  ngOnInit(): void {
    this.langStrings = Translation[this.langService.getLang()];
  }

}
