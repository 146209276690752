export class Visit {
    id: number;
    date: Date;
    protocolID: number;
    exerciseIDs: number[];
    doctorNotes: string;

    constructor(id: number, date: Date, protocolID?: number, exerciseIDs?: number[], doctorNotes?: string) {
        this.id = id;
        this.date = date;

        if (protocolID != undefined) {
            this.protocolID = protocolID;
        } 

        if (exerciseIDs != undefined) {
            this.exerciseIDs = exerciseIDs;
        } else {
            this.exerciseIDs = []
        }

        if (doctorNotes != undefined) {
            this.doctorNotes = doctorNotes;
        } else {
            this.doctorNotes = "";
        }
    }

    public getID(): number {
        return this.id;
    }

    public getDate(): Date {
        return this.date;
    }

    public getExaminationIDs(): number[] {
        return this.exerciseIDs;
    }

    public getProtocolID(): number {
        return this.protocolID;
    }

    public setProtocolID(protocolID: number) {
        this.protocolID = protocolID;
    }

    getNotes(): string {
        return this.doctorNotes;
    }
}