import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { DevicelistComponent } from './devicelist/devicelist.component';
import { PatientlistComponent } from './patientlist/patientlist.component';
import { PatientcardComponent } from './patientcard/patientcard.component';
import { AddPatientModalComponent } from './add-patient-modal/add-patient-modal.component';
import { VisitlistComponent } from './visitlist/visitlist.component';
import { DebugbarComponent } from './debugbar/debugbar.component';

import { DlDateTimeDateModule, DlDateTimePickerModule } from "angular-bootstrap-datetimepicker";
import { DevicecardComponent } from './devicecard/devicecard.component';
import { ExaminationdetailsComponent } from './examinationdetails/examinationdetails.component';
import { ExaminationProgressModalComponent } from './examination-progress-modal/examination-progress-modal.component';
import { VisitPrintViewComponent } from './visit-print-view/visit-print-view.component';
import { ProtocollistComponent } from './protocollist/protocollist.component';
import { DevicePositionSelectorComponent } from './device-position-selector/device-position-selector.component';
import { ConnectionStatusComponent } from './connection-status/connection-status.component';
import { ExercisedetailsComponent } from './exercisedetails/exercisedetails.component';
import { ExerciseResultsComponent, RoundOneDecimalPipe } from './exercise-results/exercise-results.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    DevicelistComponent,
    PatientlistComponent,
    PatientcardComponent,
    AddPatientModalComponent,
    VisitlistComponent,
    DebugbarComponent,
    DevicecardComponent,
    ExaminationdetailsComponent,
    ExaminationProgressModalComponent,
    VisitPrintViewComponent,
    ProtocollistComponent,
    DevicePositionSelectorComponent,
    ConnectionStatusComponent,
    ExercisedetailsComponent,
    ExerciseResultsComponent,
    RoundOneDecimalPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    DlDateTimeDateModule,
    DlDateTimePickerModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
