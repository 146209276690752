<div class="row">
    <div *ngFor="let device of devices" class="col-xl-4">
        <div class="card mb-3 rounded-5 shadow-sm">
            <div [ngClass]="
            {'bg-secondary': device.connectionStatus == 0 || device.connectionStatus == 1 , 'bg-primary': device.connectionStatus == 2}" 
            class="card-header text-center py-1 text-white d-flex justify-content-between">
            <!-- <div class="card-header text-center py-1 text-white bg-primary d-flex justify-content-between"> -->
                {{langStrings['device']}} {{device.id}}
            </div>
            <div class="card-body">
                <app-devicecard [device]="device"></app-devicecard>
            </div>
        </div>
    </div>
</div>

<div class="text-center">
    <button class="btn scan-button" [ngClass]="{'btn-secondary': isScanning, 'btn-success': !isScanning}" (click)="startScan()" [disabled]="isScanning">
        <div *ngIf="isScanning" class="spinner-border spinner-border-sm text-warning" role="status">
        </div>
        <div *ngIf="!isScanning">{{langStrings['scan-button']}}</div>
    </button>
</div>