import { Component, Input, OnInit } from '@angular/core';
import { ConnectionStatusType } from '../models/ConnectionStatusType';
import { Device } from '../models/Device';
import { DeviceLocation } from '../models/DeviceLocation';
import { LanguageService } from '../services/language.service';
import Translation from '../translation/devicecard.json'
import DeviceLocationTranslation from '../translation/DeviceLocation.json'

@Component({
  selector: 'app-devicecard',
  templateUrl: './devicecard.component.html',
  styleUrls: ['./devicecard.component.css']
})
export class DevicecardComponent implements OnInit {
  public langStrings;
  public deviceLocationStrings;
  public deviceLocations = DeviceLocation
  public connectionStatusTypes = ConnectionStatusType;
  @Input() device: Device;

  constructor(public langService: LanguageService) { }

  ngOnInit(): void {
    this.langStrings = Translation[this.langService.getLang()];
    this.deviceLocationStrings = DeviceLocationTranslation[this.langService.getLang()];
  }

}
