export class Exercise {
    id: number;
    typeId: number;
    sortWeight: number;
    dateCompleted: Date;
    duration: number;
    results: any;

    name_pl_PL: string;
    name_en_US: string;

    desciption_pl_PL: string;
    desciption_en_US: string;

    constructor(id: number, typeId: number, sortWeight: number, name_pl_PL: string, name_en_US: string, desciption_pl_PL: string, desciption_en_US: string, duration: number, dateCompleted?: Date, results?: any) {
        this.id = id;
        this.typeId = typeId;
        this.sortWeight = sortWeight;
        this.name_pl_PL = name_pl_PL;
        this.name_en_US = name_en_US;
        this.desciption_pl_PL = desciption_pl_PL;
        this.desciption_en_US = desciption_en_US;
        this.duration = duration;
        this.dateCompleted = dateCompleted;
        this.results = results;
    }

    getName(lang): string {
        if (lang == "pl-PL") {
            return this.name_pl_PL;
        } else {
            return this.name_en_US;
        }
    }

    getDescription(lang): string {
        if (lang == "pl-PL") {
            return this.desciption_pl_PL;
        } else {
            return this.desciption_en_US;
        }
    }
}