import { Component, OnInit } from '@angular/core';
import { DevicesService } from '../services/devices.service';
import { PatientsService } from '../services/patients.service';

@Component({
  selector: 'app-debugbar',
  templateUrl: './debugbar.component.html',
  styleUrls: ['./debugbar.component.css']
})
export class DebugbarComponent implements OnInit {

  constructor(private patientsService: PatientsService, private devicesService: DevicesService) { }

  ngOnInit(): void {
  }

  createFakePatients(): void {
    this.patientsService.createDebugPatients().then(() => {
      location.reload();
    });
  }

  deleteAllPatients(): void {
    this.patientsService.deleteAllPatients().then(() => {
      location.reload();  
    });
  }

  createFakeDevices(): void {
    this.devicesService.createDebugDevices().then(() => {
      location.reload();
    });
  }

  deleteAllDevices(): void {
    this.devicesService.deleteAllDevices().then(() => { 
      location.reload();
    });
  }

}
