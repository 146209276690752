import { ConnectionStatusType } from "./ConnectionStatusType";
import { DeviceLocation } from "./DeviceLocation";

export class Device {
    id: number;
    macAddress: string;
    name: string;
    photoUrl: string;
    lastSeen: Date;
    position: DeviceLocation;
    connectionStatus: ConnectionStatusType;
    batteryLevel: number;
    

    constructor(id: number, macAddress: string, name: string, photoUrl: string, lastSeen: Date, position: DeviceLocation, connectionStatus: ConnectionStatusType, batteryLevel: number) {
        this.id = id;
        this.macAddress = macAddress;
        this.name = name;
        this.photoUrl = photoUrl;
        this.lastSeen = lastSeen;
        this.position = position;
        this.connectionStatus = connectionStatus;
        this.batteryLevel = batteryLevel;
    }
}