<div class="accordion my-2" id="accordionFlushExample">

    <div class="accordion-item" *ngFor="let excercise of excersises">
      <h2 class="accordion-header" id="flush-heading{{excercise.typeId}}">
        <!-- Wide View -->
        <button class="accordion-button collapsed d-flex justify-content-between d-none d-sm-flex" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#flush-collapse' + excercise.typeId" aria-expanded="false" aria-controls="flush-collapse{{excercise.typeId}}">
            <div class="col-8 me-3">
              {{excercise.getName(langStrings["lang"])}}
            </div>
            <div class="col me-3 text-end">
              <span *ngIf="!excercise.dateCompleted" class="badge bg-warning">{{langStrings['not-performed']}}</span>
              <span *ngIf="excercise.dateCompleted" class="badge bg-success">{{excercise.dateCompleted.toLocaleString(langStrings['lang'], {dateStyle: "short", timeStyle: "short"})}}</span>
            </div>
        </button>
        <!-- Narrow View -->
        <button class="accordion-button collapsed d-flex justify-content-between d-flex d-sm-none" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#flush-collapse' + excercise.typeId" aria-expanded="false" aria-controls="flush-collapse{{excercise.typeId}}">
          <div class="col me-3">
            {{excercise.getName(langStrings["lang"])}}
          </div>
          <div class="col me-3 text-end">
            <span *ngIf="!excercise.dateCompleted" class="badge bg-warning">{{langStrings['not-performed']}}</span>
            <span *ngIf="excercise.dateCompleted" class="badge bg-success">{{excercise.dateCompleted.toLocaleString(langStrings['lang'], {dateStyle: "short", timeStyle: "short"})}}</span>
          </div>
        </button>
        
      </h2>
      <div id="flush-collapse{{excercise.typeId}}" class="accordion-collapse collapse" [ngClass]="{'show': isPrintMode}" aria-labelledby="flush-heading{{excercise.typeId}}">
        <div class="accordion-body">
          <div>
            {{excercise.getDescription(langStrings["lang"])}}
          </div>
          <div>
            <app-exercise-results *ngIf="excercise.results != undefined" [exercise]="excercise"></app-exercise-results>
          </div>
          <div *ngIf="!isPrintMode" class="text-end mt-3 d-flex justify-content-end">
            <div class="col-md-4 my-0">
            </div>
            <div><button type="button" class="btn btn-primary ms-2" (click)="startModal(excercise.id)">{{langStrings['perform']}}</button></div>
          </div>
        </div>
      </div>
    </div>
    </div>

<button type="button" class="invisible" id="toggleProgressModal" data-bs-toggle="modal" data-bs-target="#examinationProgressBar">{{langStrings['perform']}}</button>
<app-examination-progress-modal *ngIf="selectedExercise != undefined && !isPrintMode" (onSwitchToNextExercise)="switchToNextExercise()" (onDurationChange)="updateDurationOnSelectedExercise($event)" (onNeedExerciseUpdate)="updaterExercise()" [examination]="selectedExercise" [isDurationCorrect]="isDurationCorrect"></app-examination-progress-modal>
