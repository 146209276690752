<div *ngIf="patient != undefined" class="row d-flex justify-content-between">
    <div class="col my-auto">
        <h4 class="mb-0">
            {{patient.firstName}} {{patient.lastName}}
        </h4>
    </div>
    <div class="col-3">
        <img class="rounded-circle float-end me-4" src="assets/default-avatar.png" height="60px">
    </div>
</div>
<div *ngIf="patient != undefined" class="row d-flex justify-content-between">
    <div class="col">
        <span>{{IDTypeAsString}}: {{patient.socialID.value}}</span><br>
        <span class="text-secondary">{{langStrings['birthday']}}: {{patient.birthDate.toLocaleDateString(langService.getLang())}} (<strong>{{age}}</strong>)</span><br>
        <span class="text-secondary">{{langStrings['sex']}}: {{displayedSex}}</span><br>
            
        <span class="text-secondary">{{langStrings['height']}}: {{patient.heightCM}} cm</span><br>
        <span class="text-secondary">{{langStrings['weight']}}: {{patient.weightKG}} kg</span><br>
        <span class="text-secondary">{{langStrings['leftThighLength']}}: <strong>{{patient.leftThighLength}} cm</strong></span><br>
        <span class="text-secondary">{{langStrings['rightThighLength']}}: <strong>{{patient.rightThighLength}} cm</strong></span><br>
        <span class="text-secondary">{{langStrings['leftShinLength']}}: <strong>{{patient.leftShinLength}} cm</strong></span><br>
        <span class="text-secondary">{{langStrings['rightShinLength']}}: <strong>{{patient.rightShinLength}} cm</strong></span><br>
        <span class="text-secondary">{{langStrings['leftAnkleHeight']}}: <strong>{{patient.leftAnkleHeight}} cm</strong></span><br>
        <span class="text-secondary">{{langStrings['rightAnkleHeight']}}: <strong>{{patient.rightAnkleHeight}} cm</strong></span><br>
    </div>
</div>
<span class="d-flex justify-content-center mt-2"><button *ngIf="!isPrintMode" (click)="emitEdit()" class="btn btn-sm mx-1 btn-info text-white">{{langStrings['button-edit']}}</button></span>

<div>
  <label for="validationServer01" class="mt-3">{{langStrings['notes']}}:</label>
  <textarea *ngIf="!isPrintMode" id="description" class="form-control" [(ngModel)]="doctorNotes" (change)="saveNotes()" rows="3"></textarea>
  <div *ngIf="isPrintMode">{{doctorNotes}}</div>
</div>
