import { TmplAstRecursiveVisitor } from '@angular/compiler';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Exercise } from '../models/Exercise';
import { ExaminationProtocol } from '../models/ExaminationProtocol';
import { Patient } from '../models/Patient';
import { Visit } from '../models/Visit';
import { ExaminationProtocolsService } from '../services/examination-protocols.service';
import { ExercisesService } from '../services/exercises.service';
import { LanguageService } from '../services/language.service';
import { VisitsService } from '../services/visits.service';
import Translation from "../translation/examinationdetails.json";
import { PatientsService } from '../services/patients.service';

// TODO Save notes

@Component({
  selector: 'app-examinationdetails',
  templateUrl: './examinationdetails.component.html',
  styleUrls: ['./examinationdetails.component.css']
})
export class ExaminationdetailsComponent implements OnInit {
  @Input() visit: Visit;
  @Input() patient: Patient;
  public langStrings;
  public examinationProtocols: ExaminationProtocol[] = []; 
  public seletedProtocolID: number;
  public validSelection = false;
  public isPrintMode = false;
  public doctorNotes = "";
  public excersises: Exercise[] = [];
  public selectedExamination: Exercise;
  @Output() private deleteVisitEmitter: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    public langService: LanguageService,
    private examinationProtocolsService: ExaminationProtocolsService,
    private visitsService: VisitsService,
    private patientsService: PatientsService,
    private exercisesService: ExercisesService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.langStrings = Translation[this.langService.getLang()];
    this.updateProtocols();
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      // If we are in print mode, we need to get the visit from the visits service
      if(params.get('visitID') != null) {
        this.isPrintMode = true;
        const visitID = parseInt(params.get('visitID'));
        const patientID = parseInt(params.get('patientID'));
        this.getVisitByID(visitID);
        this.getPatientByID(patientID);
        setTimeout(() => {
          window.print();
        }, 1500);
      }
    });
  }

  getVisitByID(id: number) {
    this.visitsService.getVisitByID(id).then(visit => {
      this.visit = visit;
      
      this.ngOnChanges();
    });
  }

  getPatientByID(id: number) {
    this.patientsService.getPatientByID(id).then(patient => {
      this.patient = patient;
      this.ngOnChanges();
    });

  }

  selectProtocol(id) {
    id = parseInt(id);
    this.seletedProtocolID = id;
    this.validSelection = true;
    this.visit.setProtocolID(id);
    console.log("Selected protocol: " + id);
    this.visitsService.updateVisit(this.visit).then(result => { 
      this.updateProtocols();
      this.updateExaminations();
    }, error => {
      // TODO Pretty error handling
      console.log(error);
      alert("Error: " + error.statusText);
    });
  }

  updateProtocols() {
    // Because html won't change selected <option> dynamically, we need to recreate the list of examination protocols (╯°□°）╯︵ ┻━┻
    this.examinationProtocols = [];
    setTimeout(() => {
      this.examinationProtocolsService.getExaminationProtocols().then(protocols => {
        this.examinationProtocols = protocols;
      });
    }, 10);
  }

  updateNotes() {
    this.doctorNotes = this.visit.getNotes();
  }

  updateExaminations() {
    this.exercisesService.getExercisesByVisitID(this.visit.getID()).then(excersises => {
      this.excersises = excersises;
    });
  }

  ngOnChanges() {
    if (this.visit == undefined) { return; }
    if (this.visit.getProtocolID() != undefined) {
      this.seletedProtocolID = this.visit.getProtocolID();
      this.validSelection = true;
      this.updateProtocols();
      this.updateNotes();
      this.updateExaminations();
      if (this.excersises.length > 0) {
        this.selectedExamination = this.excersises[0];
      }
    } else {
      this.validSelection = false;
    }
  }

  saveNotes() {
    this.visit.doctorNotes = this.doctorNotes;
    this.visitsService.updateVisit(this.visit).then(result => { }, error => { 
      // TODO Pretty error handling
      console.log(error);
      alert("Error: " + error.statusText);
    });
  }
  
  deleteVisit() {
    this.deleteVisitEmitter.emit(this.visit.id);
  }

}
