import { SexType } from "./SexType";
import { SocialID } from "./SocialID";
import { SocialIDType } from "./SocialIDType";

export class Patient {

    id: number;
    firstName: string;
    lastName: string;
    heightCM: number;
    weightKG: number;
    leftThighLength: number;
    rightThighLength: number;
    leftShinLength: number;
    rightShinLength: number;
    leftAnkleHeight: number;
    rightAnkleHeight: number;
    birthDate: Date;
    sex: SexType;
    socialID: SocialID;

    doctorNotes: string;

    constructor(id: number, firstName: string, lastName: string, heightCM: number, weightKG: number, leftThighLength: number, rightThighLength: number, leftShinLength: number, rightShinLength: number, leftAnkleHeight: number, rightAnkleHeight: number, birthDate: Date, sex: SexType, socialID: SocialID, doctorNotes: string) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.heightCM =  heightCM;
        this.weightKG = weightKG;
        this.leftThighLength = leftThighLength;
        this.rightThighLength = rightThighLength;
        this.leftShinLength = leftShinLength;
        this.rightShinLength = rightShinLength;
        this.leftAnkleHeight = leftAnkleHeight;
        this.rightAnkleHeight = rightAnkleHeight;
        this.birthDate = birthDate;
        this.sex = sex;
        this.socialID = socialID;
        this.doctorNotes = doctorNotes;
    }

    public getID(): number {
        return this.id;
    }

    getFirstName(): string {
        return this.firstName;
    }

    getLastName(): string {
        return this.lastName;
    }

    getSocialIDType(): SocialIDType {
        return this.socialID.getTypeID();
    }

    getSocialID(): string {
        return this.socialID.getValue();
    }

    getBirthDate(): Date {
        return this.birthDate;
    }

    getWeight(): number {
        return this.weightKG;
    }

    getHeight(): number {
        return this.heightCM;
    }

    getSex(): SexType {
        return this.sex;
    }

    getLeftThighLength(): number {
        return this.leftThighLength;
    }

    getRightThighLength(): number {
        return this.rightThighLength;
    }

    getLeftShinLength(): number {
        return this.leftShinLength;
    }

    getRightShinLength(): number {
        return this.rightShinLength;
    }

    getLeftAnkleHeight(): number {
        return this.leftAnkleHeight;
    }

    getRightAnkleHeight(): number {
        return this.rightAnkleHeight;
    }

    getNotes(): string {
      return this.doctorNotes;
    }
}
