<div class="modal fade" id="addPatient" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">{{langStrings['modalAddPatientTitle']}}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="row g-3 mb-3">
                <div class="col-lg-6">
                    <label class="form-label">{{langStrings['firstName']}}</label>
                    <input type="text" class="form-control is-invalid" [(ngModel)]="firstName" [ngClass]="{'is-valid': isFirstNameValid == validationType.valid, 'is-invalid': isFirstNameValid == validationType.invalid}" (change)="firstNameValidation()" required data-cip-id="validationServer01">
                </div>
                <div class="col-lg-6">
                    <label class="form-label">{{langStrings['lastName']}}</label>   
                    <input type="text" class="form-control is-invalid" [(ngModel)]="lastName" [ngClass]="{'is-valid': isLastNameValid == validationType.valid, 'is-invalid': isLastNameValid == validationType.invalid}" (change)="lastNameValidation()" required data-cip-id="validationServer01">
                </div>
            </div>

            <div *ngIf="showSocialIDType" class="row g-3 mb-3">
                <div class="col-lg-6">
                    <label class="form-label">{{langStrings['socialIDType']}}</label>
                    <select class="form-select" [ngClass]="{'is-valid': isSocialIDTypeValid == validationType.valid, 'is-invalid': isSocialIDTypeValid == validationType.invalid}" (change)="socialIDTypeValidation($event.target.value)" id="validationServer04" required="">
                        <option enabled="false" [selected]="socialIDType == ''" disabled>{{langStrings['choose']}}</option>
                        <option *ngFor="let enum of socialIDTypes" [selected]="enum == socialIDType" value="{{enum}}">
                            {{enum}}
                        </option>
                    </select>
                </div>
                <div class="col-lg-6">
                    <label class="form-label">{{langStrings['socialID']}}</label>
                    <input type="text" class="form-control is-invalid" [(ngModel)]="socialID" [ngClass]="{'is-valid': isSocialIDValid == validationType.valid, 'is-invalid': isSocialIDValid == validationType.invalid}" (change)="socialIDValidation()" required data-cip-id="validationServer01">
                </div>
            </div>

            <div class="row g-3 mb-3">
                <div class="col-lg-6">
                    <label class="form-label">{{langStrings['birthday']}}</label>
                    <div class="row d-flex justify-content-between">
                        <div class="col-9 pe-0">
                            <input type="text" class="form-control" value="{{selectedDate.toLocaleDateString(langService.getLang())}}" [ngClass]="{'is-valid': isBirthdayValid, 'is-invalid': !isBirthdayValid}" required data-cip-id="validationServer01" disabled>
                        </div>
                        <div class="col-3 pe-3 d-grid">
                            <button id="addPatientCalendarButton" type="button" class="btn btn-primary" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" data-bs-offset="-140, 0"><i class="bi bi-calendar"></i></button>
                            <ul class="dropdown-menu" style="min-width: 20rem; padding-left: 2rem; padding-right: 2rem;">
                                <dl-date-time-picker
                                    startView="year"
                                    maxView="year"
                                    minView="day"
                                    minuteStep="30"
                                    [(ngModel)]="selectedDate"
                                    (change)="ageUpate()"
                                    style="font-size: 15px">
                                </dl-date-time-picker>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2">
                    <label class="form-label">{{langStrings['age']}}</label>
                    <input type="text" class="form-control" [(ngModel)]="age" readonly>
                </div>
                <div *ngIf="showSexType" class="col-lg-4">
                    <label class="form-label">{{langStrings['sex']}}</label>
                    <select class="form-select" [ngClass]="{'is-valid': isSexValid == validationType.valid, 'is-invalid': isSexValid == validationType.invalid}" (change)="sexValidation($event.target.value)" id="validationServer04" required="">
                        <option enabled="false" [selected]="sex == ''" disabled>{{langStrings['choose']}}</option>
                        <option *ngFor="let enum of sexTypes" [selected]="enum == sex" value="{{enum}}">
                            {{langStrings[enum]}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="row g-3 mb-3 d-flex justify-content-center">
                
                <div class="col-lg-5">
                    <label class="form-label">{{langStrings['height']}}</label>
                    <input type="text" class="form-control is-invalid" [(ngModel)]="height" [ngClass]="{'is-valid': isHeightValid == validationType.valid, 'is-invalid': isHeightValid == validationType.invalid}" (change)="heightValidation()" required data-cip-id="validationServer01">
                </div>
                <div class="col-lg-5">
                    <label class="form-label">{{langStrings['weight']}}</label>
                    <input type="text" class="form-control is-invalid" [(ngModel)]="weight" [ngClass]="{'is-valid': isWeightValid == validationType.valid, 'is-invalid': isWeightValid == validationType.invalid}" (change)="weightValidation()" required data-cip-id="validationServer01">
                </div>
            </div>

            <div class="row g-3 mb-3 d-flex justify-content-center">
                
                <div class="col-lg-5">
                    <label class="form-label">{{langStrings['leftThighLength']}}</label>
                    <input type="text" class="form-control is-invalid" [(ngModel)]="leftThighLength" [ngClass]="{'is-valid': isLeftThighLengthValid == validationType.valid, 'is-invalid': isLeftThighLengthValid == validationType.invalid}" (change)="leftThighLengthValidation()" required data-cip-id="validationServer01">
                </div>
                <div class="col-lg-5">
                    <label class="form-label">{{langStrings['rightThighLength']}}</label>
                    <input type="text" class="form-control is-invalid" [(ngModel)]="rightThighLength" [ngClass]="{'is-valid': isRightThighLengthValid == validationType.valid, 'is-invalid': isRightThighLengthValid == validationType.invalid}" (change)="rightThighLengthValidation()" required data-cip-id="validationServer01">
                </div>
            </div>

            <div class="row g-3 mb-3 d-flex justify-content-center">
                
                <div class="col-lg-5">
                    <label class="form-label">{{langStrings['leftShinLength']}}</label>
                    <input type="text" class="form-control is-invalid" [(ngModel)]="leftShinLength" [ngClass]="{'is-valid': isLeftShinLengthValid == validationType.valid, 'is-invalid': isLeftShinLengthValid == validationType.invalid}" (change)="leftShinLengthValidation()" required data-cip-id="validationServer01">
                </div>
                <div class="col-lg-5">
                    <label class="form-label">{{langStrings['rightShinLength']}}</label>
                    <input type="text" class="form-control is-invalid" [(ngModel)]="rightShinLength" [ngClass]="{'is-valid': isRightShinLengthValid == validationType.valid, 'is-invalid': isRightShinLengthValid == validationType.invalid}" (change)="rightShinLengthValidation()" required data-cip-id="validationServer01">
                </div>
            </div>

            <div class="row g-3 mb-3 d-flex justify-content-center">
                
                <div class="col-lg-5">
                    <label class="form-label">{{langStrings['leftAnkleHeight']}}</label>
                    <input type="text" class="form-control is-invalid" [(ngModel)]="leftAnkleHeight" [ngClass]="{'is-valid': isLeftAnkleHeightValid == validationType.valid, 'is-invalid': isLeftAnkleHeightValid == validationType.invalid}" (change)="leftAnkleHeightValidation()" required data-cip-id="validationServer01">
                </div>
                <div class="col-lg-5">
                    <label class="form-label">{{langStrings['rightAnkleHeight']}}</label>
                    <input type="text" class="form-control is-invalid" [(ngModel)]="rightAnkleHeight" [ngClass]="{'is-valid': isRightAnkleHeightValid == validationType.valid, 'is-invalid': isRightAnkleHeightValid == validationType.invalid}" (change)="rightAnkleHeightValidation()" required data-cip-id="validationServer01">
                </div>
            </div>
            

        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-bs-dismiss="modal">{{langStrings['modalAddPatientCancelButton']}}</button>
            <button *ngIf="!isEditMode" type="button" class="btn btn-primary" (click)="addPatient()">{{langStrings['modalAddPatientOKButton']}}</button>
            <button *ngIf="isEditMode" type="button" class="btn btn-primary" (click)="editPatient()">{{langStrings['modalAddPatientEditButton']}}</button>
            <button style="display:none" id="dismissAddPatientModal" data-bs-dismiss="modal"></button>
        </div>
        </div>
    </div>
    </div>